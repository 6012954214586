import { mapActions, mapGetters, mapMutations } from 'vuex'
import item from '../../components/course-type-item/index.vue'
import validation from '@/mixins/validation'

export default {
  name: 'setting-course-types',
  mixin: [validation],
  data () {
    return {
      page: 1,
      showDialog: false,
      valid: '',
      selectedItem: null,
      currentLocale: 'uk',
      payload: {
        key: '',
        sort: ''
      },

      removingItem: null,
      showRemoveDialog: false,
    }
  },
  components: {
    item
  },
  computed: {
    ...mapGetters({
      list: 'settingCourseTypes/list',
      limit: 'settingCourseTypes/limit',
      listLength: 'settingCourseTypes/listLength',
      isLoading: 'settingCourseTypes/isLoading',
      isListLoading: 'settingCourseTypes/isListLoading',
      locales: 'setting/basicLocales',

      checkList: 'settingCourseTypes/checkList',
    }),
    headers () {
      return [
        {
          text: 'Name',
          value: 'name'
        },
        {
          text: 'Key',
          value: 'key'
        },
        {
          text: 'Actions',
          sortable: false,
          value: 'actions'
        }
      ]
    }
  },
  created () {
    this.fetchList()
    this.fetchBasicLocales().then(() => {
      this.currentLocale = this.locales[0].locale
    })
  },
  methods: {
    ...mapActions({
      fetchList: 'settingCourseTypes/GET_LIST',
      fetchBasicLocales: 'setting/GET_BASIC_LOCALES',
      create: 'settingCourseTypes/CREATE',
      update: 'settingCourseTypes/UPDATE',
      delete: 'settingCourseTypes/DELETE',
      checkItemInCourses: 'settingCourseTypes/CHECK_ITEM_IN_COURSES',
    }),
    ...mapMutations({
      changeSkip: 'settingCourseTypes/CHANGE_SKIP',
      resetCheckList: 'settingCourseTypes/RESET_CHECK_LIST'
    }),
    changePagination (index) {
      this.changeSkip(this.limit * (index - 1))
      this.$vuetify.goTo(0)
      this.fetchList()
    },
    changeDialog (item = null, status) {
      this.showDialog = status
      this.selectedItem = item
      if (item) {
        this.setData(item)
      } else {
        this.payload = {}
        this.payload.sort = ''
        this.payload.key = ''
      }
    },
    setData (item) {
      Object.assign(this.payload, item)
      const list = item.translations.data
      list.forEach(e => {
        this.payload[`${e.locale}[name]`] = e.name
        this.payload[`${e.locale}[description]`] = e.description
      })
    },
    updateData (data) {
      Object.assign(this.payload, data)
    },
    createRequestPayload () {
      const formData = new FormData()
      for (let i in this.payload) {
        formData.append(i, this.payload[i])
      }
      this.payload.hasOwnProperty('id') ? formData.append('_method', 'patch') : ''
      return formData
    },
    submit () {
      this.$refs.form.validate()
      if (this.valid) {
        if (this.payload.hasOwnProperty('id')) {
          this.update(this.createRequestPayload()).then(() => {
            this.$toasted.success(this.$t('success_updated'))
          })
        } else {
          this.create(this.createRequestPayload()).then(response => {
            this.selectedItem = response
            this.$toasted.success(this.$t('success_create'))
          })
        }
      }
    },

	  changeDialogDeleteItem (item, e) {
		  let $btn = e.currentTarget;

		  this.isLoadingCheckItem = true;
		  $btn.classList.add('has-disabled');

		  this.resetCheckList();

		  this.checkItemInCourses(item.id).then(() => {
			  $btn.classList.remove('has-disabled');
			  this.isLoadingCheckItem = false;

			  this.showRemoveDialog = true;
			  this.removingItem = item;
		  })
	  },

	  remove() {
		  this.delete(this.removingItem).then(() => {
			  this.showRemoveDialog = false
			  this.$toasted.success(this.$t('success_deleted'))
		  })
	  },
  }
}
